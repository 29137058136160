// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h1.homeTitle {
  color: #2d4228;
  font-size: 1.8rem;
  text-align: left;
  position: relative;
  left: 5vw;
}
div.homeEntryBox {
  border: 2px solid #516d4e;
  width: 50vw;
  margin-left: 5vw;
}
`, "",{"version":3,"sources":["webpack://./src/uploadHome.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,iBAAiB;EACjB,gBAAgB;EAChB,kBAAkB;EAClB,SAAS;AACX;AACA;EACE,yBAAyB;EACzB,WAAW;EACX,gBAAgB;AAClB","sourcesContent":["h1.homeTitle {\n  color: #2d4228;\n  font-size: 1.8rem;\n  text-align: left;\n  position: relative;\n  left: 5vw;\n}\ndiv.homeEntryBox {\n  border: 2px solid #516d4e;\n  width: 50vw;\n  margin-left: 5vw;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
