import axios from "axios";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./uploadPlants.css";
import AdminAuth from "./AdminAuth.js";

const UploadPlants = () => {
  const navigate = useNavigate();
  const [latinName, setLatinName] = useState("");
  const [chineseName, setChineseName] = useState("");
  const [commonName, setCommonName] = useState("");
  const [location, setLocation] = useState("");
  const [bloomingSeason, setBloomingSeason] = useState("");
  const [fruitingSeason, setFruitingSeason] = useState("");
  const [links, setLinks] = useState("");
  const [chineseLinks, setChineseLinks] = useState("");
  const [editor, setEditor] = useState("");
  const [picEnglishName, setPicEnglishName] = useState("");
  const [picSeason, setPicSeason] = useState("");
  const [picDescription, setPicDescription] = useState("");
  const [picPhotographer, setPicPhotographer] = useState("");
  const [picSetting, setPicSetting] = useState("");
  const [picArt, setPicArt] = useState("photography");
  const [namesArray, setNamesArray] = useState("");
  const [linkArray, setLinkArray] = useState([]);
  const [chineseLinkArray, setChineseLinkArray] = useState([]);
  const [username, setUsername] = useState("");
  const [admin, setAdmin] = useState("");
  const [otherNames, setOtherNames] = useState("");
  const [month, setMonth] = useState("");
  const [artist, setArtist] = useState("");
  const [artLocation, setArtLocation] = useState([]);
  const [plant, setPlant] = useState([]);
  const [artFiles, setArtFiles] = useState([]);
  const [picFiles, setPicFiles] = useState([]);
  const [creationPicFile, setCreationPicFile] = useState();
  const [creationArtFile, setCreationArtFile] = useState();
  const [loadingMessage, setLoadingMessage] = useState("");
  const [creationPlant, setCreationPlant] = useState("");
  const [creationCreator, setCreationCreator] = useState("");
  const [currentSubpage, setCurrentSubpage] = useState("plant");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_Source_URL}/userInfo`,
        );
        setAdmin(response.data.admin);
        setUsername(response.data.username);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [admin]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_Source_URL}/searchNames`,
        );
        var fetchedNamesArray = response.data.returnNames;
        fetchedNamesArray.sort();
        fetchedNamesArray = fetchedNamesArray.map((name) => {
          return name[0];
        });
        setNamesArray(fetchedNamesArray);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const splittedLinks = links.split(", ");
    var linkArray = [];
    splittedLinks.forEach((splittedLink) => {
      const linkParts = splittedLink.split(":");
      const linkTitle = linkParts[0];
      const link = linkParts.slice(1).join(":"); // Rejoin the remaining parts into the link
      linkArray.push({
        linkTitle: linkTitle,
        link: link,
      });
    });
    setLinkArray(linkArray);
  }, [links]);

  // const handleCreationSubmit = async (e) => {
  //   e.preventDefault();
  //   const formData = new FormData();
  //   formData.append("plant", creationPlant);
  //   formData.append("creator", creationCreator);

  //   const addFileToFormData = (file, fieldName) => {
  //     if (!file) {
  //       return false;
  //     }
  //     const fileExtension = file.name.split(".").pop();
  //     if (
  //       fileExtension !== "jpg" &&
  //       fileExtension !== "jpeg" &&
  //       fileExtension !== "png" &&
  //       fileExtension !== "webp"
  //     ) {
  //       alert("Please Upload a jpg, jpeg, png or webp file");
  //       return false;
  //     }
  //     formData.append(fieldName, file);
  //     return true;
  //   };

  //   if (creationPicFile && !addFileToFormData(creationPicFile, "pic")) {
  //     return;
  //   }
  //   if (creationArtFile && !addFileToFormData(creationArtFile, "art")) {
  //     return;
  //   }

  //   try {
  //     const response = await axios.post(
  //       `${process.env.REACT_APP_Source_URL}/uploadCreation`,
  //       formData,
  //       {
  //         headers: {
  //           "Content-Type": "multipart/form-data",
  //         },
  //       },
  //     );
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  useEffect(() => {
    const splittedLinks = chineseLinks.split(", ");
    var linkArray = [];
    splittedLinks.forEach((splittedLink) => {
      const linkParts = splittedLink.split(":");
      const linkTitle = linkParts[0];
      const link = linkParts.slice(1).join(":"); // Rejoin the remaining parts into the link
      linkArray.push({
        linkTitle: linkTitle,
        link: link,
      });
    });
    setChineseLinkArray(linkArray);
  }, [chineseLinks]);

  const handleArtFileChange = (e) => {
    setArtFiles(e.target.files);
  };

  const handlePicFileChange = (e) => {
    setPicFiles(e.target.files);
  };

  const handleArtSubmit = async (e) => {
    e.preventDefault();

    setLoadingMessage("loading");

    const formData = new FormData();
    formData.append("plant", plant);
    formData.append("artist", artist);
    formData.append("artLocation", artLocation);

    if (artFiles) {
      for (let i = 0; i < artFiles.length; i++) {
        const file = artFiles[i];
        const fileExtension = file.name.split(".").pop();
        if (
          fileExtension !== "jpg" &&
          fileExtension !== "jpeg" &&
          fileExtension !== "png" &&
          fileExtension !== "webp"
        ) {
          alert("Please upload a jpg, jpeg, png or webp file");
          return;
        }
        formData.append("files", file);
      }
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Source_URL}/uploadArt`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoadingMessage("loading");

    const formData = new FormData();
    formData.append("latinName", latinName);
    formData.append("chineseName", chineseName);
    formData.append("location", location);
    formData.append("bloomingSeason", bloomingSeason);
    formData.append("commonName", commonName);
    formData.append("editor", editor);
    formData.append("link", JSON.stringify(linkArray));
    formData.append("chineseLink", JSON.stringify(chineseLinkArray));
    formData.append("otherNames", otherNames);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Source_URL}/upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  // const handleCreationPicChange = (e) => {
  //   setCreationPicFile(e.target.files[0]);
  // };

  // const handleCreationArtChange = (e) => {
  //   setCreationArtFile(e.target.files[0]);
  // };

  const handlePicSubmit = async (e) => {
    e.preventDefault();
    setLoadingMessage("Loading...");
    const formData = new FormData();

    formData.append("picEnglishName", picEnglishName);
    formData.append("picSeason", picSeason);
    formData.append("picDescription", picDescription);
    formData.append("picPhotographer", picPhotographer);
    formData.append("picSetting", picSetting);
    formData.append("picArt", picArt);
    formData.append("month", month);

    if (picFiles) {
      for (let i = 0; i < picFiles.length; i++) {
        const file = picFiles[i];
        const fileExtension = file.name.split(".").pop();
        if (
          fileExtension !== "jpg" &&
          fileExtension !== "jpeg" &&
          fileExtension !== "png" &&
          fileExtension !== "webp"
        ) {
          alert("Please upload a jpg, jpeg, png or webp file");
          return;
        }
        formData.append("files", file);
      }
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Source_URL}/uploadPic`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="upload">
        <h1 className="dUpTitle">Database</h1>
        <div className="uploadContent">
          <div className="postForm">
            <h2 className="uploadTitle">Species Information Profile</h2>
            <form encType="multipart/form-data" onSubmit={handleSubmit}>
              <div className="form-group">
                <div className="topInputs">
                  <input
                    type="text"
                    id="latinName"
                    name="latinName"
                    placeholder="Latin Name"
                    className="inputBox"
                    value={latinName}
                    onChange={(e) => setLatinName(e.target.value)}
                    required
                  />
                  <input
                    type="text"
                    id="chineseName"
                    name="chineseName"
                    placeholder="Chinese Name"
                    className="inputBox"
                    value={chineseName}
                    onChange={(e) => setChineseName(e.target.value)}
                    required
                  />
                  <input
                    type="text"
                    id="commonName"
                    name="commonName"
                    placeholder="Common Name"
                    className="inputBox"
                    value={commonName}
                    onChange={(e) => setCommonName(e.target.value)}
                    required
                  />
                  <input
                    type="text"
                    id="otherNames"
                    name="otherNames"
                    placeholder="Common Names (Optional)"
                    className="inputBox"
                    value={otherNames}
                    onChange={(e) => setOtherNames(e.target.value)}
                  />
                  <input
                    type="text"
                    id="location"
                    name="location"
                    placeholder="location (Optional)"
                    className="inputBox"
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                  />
                </div>
                <br />
                <textarea
                  id="bloomingSeason"
                  name="bloomingSeason"
                  rows={7}
                  placeholder="Additional Information (Optional): &#13;&#13;Ex.&#13;Blooming Season 花期:&#13;Fruiting Season 果期:&#13;Interesting Facts 有趣的发现:"
                  className="inputBox additionalInfo"
                  value={bloomingSeason}
                  onChange={(e) => setBloomingSeason(e.target.value)}
                />
                <br />
                <br />

                <input
                  type="text"
                  id="links"
                  name="links"
                  placeholder="百科网站与链接（English）, 格式为 Website name: websitename.com"
                  className="inputBox iPTLinks"
                  value={links}
                  onChange={(e) => setLinks(e.target.value)}
                />
                <br />
                <br />
                <input
                  type="text"
                  id="chineseLinks"
                  name="chineseLinks"
                  placeholder="百科网站与链接（中文）, 格式为 网站名: 网站名.com"
                  className="inputBox iPTLinks"
                  value={chineseLinks}
                  onChange={(e) => setChineseLinks(e.target.value)}
                />
                <br />
                <br />
                <input
                  type="text"
                  id="editor"
                  name="editor"
                  placeholder="Editor: Name Grade"
                  className="inputBox editor"
                  value={editor}
                  onChange={(e) => setEditor(e.target.value)}
                />
                <br />
              </div>
              <button type="submit" className="formSubmit">
                Submit
              </button>
            </form>
          </div>
          <br />
          <div
            className="pictureForm"
            onDragEnter={(e) => e.preventDefault()}
            onDragOver={(e) => e.preventDefault()}
            onDrop={(e) => {
              e.preventDefault();
              setPicFiles(e.dataTransfer.files);
            }}
          >
            <form encType="multipart/form-data" onSubmit={handlePicSubmit}>
              <h2 className="uploadTitle">Picture upload</h2>
              <div className="picTop">
                <input
                  type="text"
                  id="picEnglishName"
                  name="picEnglishName"
                  rows={2}
                  placeholder="Species Name (Latin)  Type to search"
                  className="inputBox picLatin"
                  value={picEnglishName}
                  onChange={(e) => setPicEnglishName(e.target.value)}
                  required
                />
                <input
                  type="text"
                  id="picDescription"
                  name="picDescription"
                  placeholder="Description"
                  className="inputBox description"
                  value={picDescription}
                  onChange={(e) => setPicDescription(e.target.value)}
                  required
                />
              </div>
              <h3 className="picTitle">Timeline</h3>
              <div className="picMid">
                <select
                  id="season"
                  name="season"
                  className="inputBox"
                  value={picSeason}
                  onChange={(e) => setPicSeason(e.target.value)}
                  required
                >
                  <option value="">Select a season</option>
                  <option value="spring">Spring</option>
                  <option value="summer">Summer</option>
                  <option value="autumn">Autumn</option>
                  <option value="winter">Winter</option>
                </select>
                <input
                  type="month"
                  id="month"
                  name="month"
                  className="inputBox"
                  value={month}
                  onChange={(e) => setMonth(e.target.value)}
                  required
                />
                <label htmlFor="picFiles" className="fileLabel">
                  {picFiles.length === 0
                    ? "Click to upload file(s)"
                    : picFiles.length + " file(s) selected"}
                </label>
                <input
                  id="picFiles"
                  type="file"
                  name="files"
                  className="file"
                  onChange={handlePicFileChange}
                  multiple
                />
              </div>
              <br />
              <div className="picBtm">
                <input
                  type="text"
                  id="picPhotographer"
                  name="picPhotographer"
                  placeholder="Photographer"
                  className="inputBox"
                  value={picPhotographer}
                  onChange={(e) => setPicPhotographer(e.target.value)}
                  required
                />
                <input
                  type="text"
                  id="picSetting"
                  name="picSetting"
                  placeholder="Setting"
                  className="inputBox"
                  value={picSetting}
                  onChange={(e) => setPicSetting(e.target.value)}
                  required
                />
              </div>
              <button type="submit" className="formSubmit">
                Submit
              </button>
            </form>
          </div>
          <br />
          <div
            className="artForm"
            onDragEnter={(e) => e.preventDefault()}
            onDragOver={(e) => e.preventDefault()}
            onDrop={(e) => {
              e.preventDefault();
              setArtFiles(e.dataTransfer.files);
            }}
          >
            <form encType="multipart/form-data" onSubmit={handleArtSubmit}>
              <h2 className="uploadTitle">Artwork</h2>
              <div className="artTop">
                <input
                  type="text"
                  id="artPlant"
                  name="plant"
                  placeholder="Species Name (Latin) Type to search"
                  className="inputBox"
                  value={plant}
                  onChange={(e) => setPlant(e.target.value)}
                  required
                />
                <input
                  type="text"
                  id="artist"
                  name="artist"
                  placeholder="Creator: Name(s) Grade"
                  className="inputBox"
                  value={artist}
                  onChange={(e) => setArtist(e.target.value)}
                  required
                />
                <label htmlFor="artFiles" className="fileLabel">
                  {artFiles.length === 0
                    ? "Click to upload file(s)"
                    : artFiles.length + " file(s) selected"}
                </label>
                <input
                  id="artFiles"
                  type="file"
                  name="files"
                  className="file"
                  onChange={handleArtFileChange}
                  multiple
                />
              </div>
              <br />
              <input
                type="text"
                id="artLocation"
                name="location"
                placeholder="Location"
                className="inputBox artLocation"
                value={artLocation}
                onChange={(e) => setArtLocation(e.target.value)}
                required
              />
              <br />
              <button type="submit" className="formSubmit">
                Submit
              </button>
            </form>
          </div>
          <h3>{loadingMessage}</h3>
        </div>
        <br />
        <br />
      </div>
    </>
  );
};

export default UploadPlants;
