import axios from "axios";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import levenshtein from "fast-levenshtein";
import { Link } from "react-router-dom";
import "./db2.css";
import SearchBar from "./SearchBar.js";

const DatabaseTwo = ({ handleGet, setLoading }) => {
  const [query, setQuery] = useState("");
  const [namesArray, setNamesArray] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [numOfPlants, setNumOfPlants] = useState("");
  const [pics, setPics] = useState([]);
  const [load, setLoad] = useState([true, true, true]);
  const [loadedSrc, setLoadedSrc] = useState(["", "", ""]);

  useEffect(() => {
    const getDb2Pic = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_Source_URL}/getDb2Pic`,
        );
        setPics(response.data.pics);
      } catch (error) {
        console.log(error);
      }
    };

    getDb2Pic();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_Source_URL}/searchNames`,
        );
        const fetchedNamesArray = response.data.returnNames;
        setNamesArray(fetchedNamesArray);
        setNumOfPlants(response.data.numOfPlants);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  const handleSearch = (e) => {
    const inputValue = e.target.value;
    const results = [];
    setQuery(inputValue);
    namesArray.forEach((name) => {
      const distance1 = levenshtein.get(name[0], query);
      const distance2 = levenshtein.get(name[1], query);
      const distance3 = levenshtein.get(name[2], query);
      if (distance1 < 4 || distance2 < 3 || distance3 < 4) {
        results.push(name);
      }
    });

    setSearchResults(results);
  };

  useEffect(() => {
    var newArray = [true, true, true];
    var srcs = [];
    setLoad(newArray);
    pics.forEach((pic, index) => {
      const img = new Image();
      img.src = pic.path;
      img.onload = () => {
        newArray[index] = false;
        srcs[index] = pic.path;
        setLoadedSrc([...srcs]);
        setLoad([...newArray]);
      };
      img.onerror = async () => {
        try {
          const response = await axios.get("/db2Alt");
          const altImg = new Image();
          altImg.src = response.data.pic.path;
          altImg.onload = () => {
            newArray[index] = false;
            srcs[index] = response.data.pic.path;
            setLoadedSrc([...srcs]);
            setLoad([...newArray]);
          };
          altImg.onerror = () => {
            console.error("Failed to load alt image");
          };
        } catch (error) {
          console.log(error);
        }
      };
    });
    setLoading(false);
  }, [pics, setLoading]); // Image Load Function

  return (
    <div onLoad={() => setLoading(false)}>
      <div className="databaseTwo">
        <div className="dbBlock" />
        <h1 className="db2ttl">
          Database of Plant Species in School 校内植物检索数据库
        </h1>
        <SearchBar
          handleGet={handleGet}
          searchResults={searchResults}
          query={query}
          handleSearch={handleSearch}
        />

        <div className="numOfPlantsBox">
          <div className="msg"># of Plant Species recorded in Database</div>
          <div className="number">
            <p className="numberP">~{numOfPlants}</p>
          </div>
        </div>
        <Link to="/glossary" className="link">
          Show the Full Species List......
        </Link>
        <p
          style={{
            fontSize: "1.2rem",
            textDecoration: "underline",
            marginLeft: "8rem",
          }}
        >
          Looking for What's New (click photos to see more)......
        </p>
        <div className="db2pics">
          {pics.length === 3
            ? pics.map((pic, index) => {
                return (
                  <div key={index} className="pic">
                    {load[index] ? (
                      <div className="db2picAlt" />
                    ) : (
                      <img
                        style={{ cursor: "pointer" }}
                        src={loadedSrc[index]}
                        alt="plant"
                        className="picImg"
                        onClick={() =>
                          handleGet(
                            loadedSrc[index].split("/")[2].split("-")[0],
                          )
                        }
                      />
                    )}
                  </div>
                );
              })
            : Array.from({ length: 3 }, (_, index) => index).map((index) => {
                return (
                  <div key={index} className="pic">
                    {load[index] && <div className="db2picAlt" />}
                  </div>
                );
              })}
        </div>
      </div>
    </div>
  );
};

export default DatabaseTwo;
