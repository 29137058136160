import axios from "axios";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import levenshtein from "fast-levenshtein";
import { Link } from "react-router-dom";
import DatabaseOne from "./DatabaseOne";
import DatabaseTwo from "./DatabaseTwo.js";
import DatabaseThree from "./DatabaseThree.js";
import "./database.css";
import Navbar from "./Navbar.js";

const Database = (prop) => {
  const [search, setSearch] = useState([]);
  const [key, setKey] = useState("");
  const [loading, setLoading] = useState(true);

  const handleEditPage = prop.handleEditPage;

  useEffect(() => {
    document.title = "Database 植物数据库";
  }, []);

  const handleGet = (input) => {
    setSearch(input);
  };

  const gallerySearch = (input) => {
    setKey(null);
    setSearch(input);
  };

  const handleGallery = (input) => {
    setKey(input);
  };

  const handleBack = (input) => {
    setKey(null);
    setSearch(input);
  };

  if (key) {
    return (
      <section onLoad={() => setLoading(false)} className="db">
        <Navbar />
        <div className="databaseOne">
          <DatabaseThree
            customKey={key}
            handleBack={handleBack}
            gallerySearch={gallerySearch}
          />
        </div>
        <footer className="dbbeian">
          <a
            href="https://beian.miit.gov.cn"
            rel="noreferrer"
            target="_blank"
            className="beian"
          >
            湘ICP备2023027594号-1
          </a>
        </footer>
      </section>
    );
  } else if (prop.search) {
    return (
      <section className="db">
        <Navbar />
        <div className="databaseOne">
          <DatabaseOne
            search={prop.search}
            handleGallery={handleGallery}
            handleEditPage={handleEditPage}
          />
        </div>
        <footer className="dbbeian">
          <a
            href="https://beian.miit.gov.cn"
            rel="noreferrer"
            target="_blank"
            className="beian"
          >
            湘ICP备2023027594号-1
          </a>
        </footer>
      </section>
    );
  } else if (search.length > 0) {
    return (
      <section className="db">
        <Navbar />
        <div className="databaseOne">
          <DatabaseOne
            handleGallery={handleGallery}
            search={search}
            handleEditPage={handleEditPage}
          />
        </div>
        <footer className="dbbeian">
          <a
            href="https://beian.miit.gov.cn"
            rel="noreferrer"
            target="_blank"
            className="beian"
          >
            湘ICP备2023027594号-1
          </a>
        </footer>
      </section>
    );
  } else {
    return (
      <section className="db dbAdd">
        <Navbar />
        {loading && (
          <section className="loadingCreation">
            <div class="dots-container">
              <div class="dots"></div>
              <div class="dots"></div>
              <div class="dots"></div>
              <div class="dots"></div>
              <div class="dots"></div>
            </div>
          </section>
        )}
        <div className="databaseTwo">
          <DatabaseTwo handleGet={handleGet} setLoading={setLoading} />
        </div>
        <footer className="dbbeian1">
          <a
            href="https://beian.miit.gov.cn"
            rel="noreferrer"
            target="_blank"
            className="beian"
          >
            湘ICP备2023027594号-1
          </a>
        </footer>
      </section>
    );
  }
};

export default Database;
