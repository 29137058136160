// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.db2SearchButton {
  position: relative;
  flex-grow: 1;
  color: black;
}
.searchResultsButton {
  text-align: left;
  display: flex;
  width: auto;
  align-items: center;
  width: 61.5vw;
  pointer-events: auto;
  cursor: pointer;
  height: 2rem;
  padding: 0 0.6rem 0 0.6rem;
  background-color: white;
}
h2.db1h2 {
  font-size: 17px;
  font-weight: 100;
  position: relative;
}
form.db2SubmitForm {
  display: flex;
  width: 68%;
  padding: 0.5rem 2rem 0.5rem 3rem;
  background-color: #fffffa;
  border-radius: 50px;
  flex-direction: row;
  align-items: center;
  position: relative;
  margin: 0 auto;
  overflow: hidden;
}
.db2Results {
  position: absolute;
  z-index: 9999;
  display: flex;
  gap: 3px;
  flex-direction: column;
  left: 14.5vw;
}
.db2SearchBar {
  position: relative;
  border: none;
  font-size: 1.3rem;
  outline: none;
  flex-grow: 19;
  background-color: transparent;
  color: black;
}
.db2SearchButton:hover {
  color: #516d4e;
}
`, "",{"version":3,"sources":["webpack://./src/searchBar.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,YAAY;EACZ,YAAY;AACd;AACA;EACE,gBAAgB;EAChB,aAAa;EACb,WAAW;EACX,mBAAmB;EACnB,aAAa;EACb,oBAAoB;EACpB,eAAe;EACf,YAAY;EACZ,0BAA0B;EAC1B,uBAAuB;AACzB;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,kBAAkB;AACpB;AACA;EACE,aAAa;EACb,UAAU;EACV,gCAAgC;EAChC,yBAAyB;EACzB,mBAAmB;EACnB,mBAAmB;EACnB,mBAAmB;EACnB,kBAAkB;EAClB,cAAc;EACd,gBAAgB;AAClB;AACA;EACE,kBAAkB;EAClB,aAAa;EACb,aAAa;EACb,QAAQ;EACR,sBAAsB;EACtB,YAAY;AACd;AACA;EACE,kBAAkB;EAClB,YAAY;EACZ,iBAAiB;EACjB,aAAa;EACb,aAAa;EACb,6BAA6B;EAC7B,YAAY;AACd;AACA;EACE,cAAc;AAChB","sourcesContent":[".db2SearchButton {\n  position: relative;\n  flex-grow: 1;\n  color: black;\n}\n.searchResultsButton {\n  text-align: left;\n  display: flex;\n  width: auto;\n  align-items: center;\n  width: 61.5vw;\n  pointer-events: auto;\n  cursor: pointer;\n  height: 2rem;\n  padding: 0 0.6rem 0 0.6rem;\n  background-color: white;\n}\nh2.db1h2 {\n  font-size: 17px;\n  font-weight: 100;\n  position: relative;\n}\nform.db2SubmitForm {\n  display: flex;\n  width: 68%;\n  padding: 0.5rem 2rem 0.5rem 3rem;\n  background-color: #fffffa;\n  border-radius: 50px;\n  flex-direction: row;\n  align-items: center;\n  position: relative;\n  margin: 0 auto;\n  overflow: hidden;\n}\n.db2Results {\n  position: absolute;\n  z-index: 9999;\n  display: flex;\n  gap: 3px;\n  flex-direction: column;\n  left: 14.5vw;\n}\n.db2SearchBar {\n  position: relative;\n  border: none;\n  font-size: 1.3rem;\n  outline: none;\n  flex-grow: 19;\n  background-color: transparent;\n  color: black;\n}\n.db2SearchButton:hover {\n  color: #516d4e;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
