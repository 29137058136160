// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.namesEdit {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 27vw;
  gap: 1vw;
}
div.secEdit {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 22vw;
  gap: 1vw;
}
textarea.additionalInfoBox {
  width: 41vw;
}
input.linksBox {
  width: 41vw;
}
`, "",{"version":3,"sources":["webpack://./src/editPageTextForm.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,qCAAqC;EACrC,WAAW;EACX,QAAQ;AACV;AACA;EACE,aAAa;EACb,qCAAqC;EACrC,WAAW;EACX,QAAQ;AACV;AACA;EACE,WAAW;AACb;AACA;EACE,WAAW;AACb","sourcesContent":["div.namesEdit {\n  display: grid;\n  grid-template-columns: repeat(3, 1fr);\n  width: 27vw;\n  gap: 1vw;\n}\ndiv.secEdit {\n  display: grid;\n  grid-template-columns: repeat(2, 1fr);\n  width: 22vw;\n  gap: 1vw;\n}\ntextarea.additionalInfoBox {\n  width: 41vw;\n}\ninput.linksBox {\n  width: 41vw;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
