// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.creationUpload {
  position: relative;
  margin-left: 4vw;
}
div.creationPic {
  display: grid;
  width: 75vw;
  grid-template-columns: repeat(2, 1fr);
  gap: 2vw;
}
div.creationArt {
  display: grid;
  width: 75vw;
  grid-template-columns: repeat(2, 1fr);
  gap: 2vw;
}
input.creationUpPlant {
  width: 25vw;
}
input.leftInput {
  width: 45vw;
}
div.creationEntry {
  border: 2px solid #516d4e;
  margin-right: 4vw;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  padding: 2rem 3.5rem 2rem 3.5rem;
  gap: 2vw;
  background-color: white;
}

img.upCreationPic {
  height: 20vw;
  width: 36vw;
  object-fit: cover;
}

img.upCreationArt {
  height: 26.5vw;
  width: 20vw;
  object-fit: cover;
}
button.featureBtn {
  position: relative;
  margin: 1rem;
  font-size: 1.2rem;
  cursor: pointer;
  padding: 0.6rem 1rem 0.6rem 1rem;
  background-color: #739667;
}
h3.creationTitle {
  color: #2d4228;
  font-weight: 400;
}
`, "",{"version":3,"sources":["webpack://./src/uploadCreation.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,WAAW;EACX,qCAAqC;EACrC,QAAQ;AACV;AACA;EACE,aAAa;EACb,WAAW;EACX,qCAAqC;EACrC,QAAQ;AACV;AACA;EACE,WAAW;AACb;AACA;EACE,WAAW;AACb;AACA;EACE,yBAAyB;EACzB,iBAAiB;EACjB,aAAa;EACb,qCAAqC;EACrC,mBAAmB;EACnB,gCAAgC;EAChC,QAAQ;EACR,uBAAuB;AACzB;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,iBAAiB;AACnB;;AAEA;EACE,cAAc;EACd,WAAW;EACX,iBAAiB;AACnB;AACA;EACE,kBAAkB;EAClB,YAAY;EACZ,iBAAiB;EACjB,eAAe;EACf,gCAAgC;EAChC,yBAAyB;AAC3B;AACA;EACE,cAAc;EACd,gBAAgB;AAClB","sourcesContent":["div.creationUpload {\n  position: relative;\n  margin-left: 4vw;\n}\ndiv.creationPic {\n  display: grid;\n  width: 75vw;\n  grid-template-columns: repeat(2, 1fr);\n  gap: 2vw;\n}\ndiv.creationArt {\n  display: grid;\n  width: 75vw;\n  grid-template-columns: repeat(2, 1fr);\n  gap: 2vw;\n}\ninput.creationUpPlant {\n  width: 25vw;\n}\ninput.leftInput {\n  width: 45vw;\n}\ndiv.creationEntry {\n  border: 2px solid #516d4e;\n  margin-right: 4vw;\n  display: grid;\n  grid-template-columns: repeat(3, 1fr);\n  align-items: center;\n  padding: 2rem 3.5rem 2rem 3.5rem;\n  gap: 2vw;\n  background-color: white;\n}\n\nimg.upCreationPic {\n  height: 20vw;\n  width: 36vw;\n  object-fit: cover;\n}\n\nimg.upCreationArt {\n  height: 26.5vw;\n  width: 20vw;\n  object-fit: cover;\n}\nbutton.featureBtn {\n  position: relative;\n  margin: 1rem;\n  font-size: 1.2rem;\n  cursor: pointer;\n  padding: 0.6rem 1rem 0.6rem 1rem;\n  background-color: #739667;\n}\nh3.creationTitle {\n  color: #2d4228;\n  font-weight: 400;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
