import React, { useEffect, useState } from "react";
import axios from "axios";
import { ReactComponent as PreviousIcon } from "./buttons/caret-back-outline.svg";
import { ReactComponent as NextIcon } from "./buttons/caret-forward-outline.svg";
import "./home.css";
import levenshtein from "fast-levenshtein";
import { useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import SearchBar from "./SearchBar";

const Home = ({ handleGets }) => {
  const navigate = useNavigate();
  const [plants, setPlants] = useState([]); // [plant1, plant2, plant3, ...
  const [arts, setArts] = useState([]); // [art1, art2, art3, ...
  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(true);
  const [admin, setAdmin] = useState("");
  const [featuredPicsArray, setFeaturedPicsArray] = useState([]);
  const [currentPic, setCurrentPic] = useState();
  const [artPaths, setArtPaths] = useState([]);
  const [picsArrayIndex, setPicsArrayIndex] = useState(0);
  const [query, setQuery] = useState("");
  const [namesArray, setNamesArray] = useState([]);
  const [searchResults, setSearchResults] = useState([]);

  const handleGet = (search) => {
    handleGets(search);
    navigate("/search");
  };

  useEffect(() => {
    document.title = "BIPH FLORA 识草木";
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_Source_URL}/searchNames`,
        );
        const fetchedNamesArray = response.data.returnNames;
        setNamesArray(fetchedNamesArray);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  const handleSearch = (e) => {
    const inputValue = e.target.value;
    const results = [];
    setQuery(inputValue);
    namesArray.forEach((name) => {
      const distance1 = levenshtein.get(name[0], query);
      const distance2 = levenshtein.get(name[1], query);
      const distance3 = levenshtein.get(name[2], query);
      if (distance1 < 4 || distance2 < 3 || distance3 < 4) {
        results.push(name);
      }
    });

    setSearchResults(results);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_Source_URL}/userInfo`,
        );
        setUsername(response.data.username);
        setAdmin(response.data.admin);
        setFeaturedPicsArray(
          response.data.featureLists.flatMap((element) =>
            element.plant.flatMap((plant) => plant.pic.path),
          ),
        );
        setArtPaths(
          response.data.featureLists.flatMap((element) =>
            element.plant.flatMap((plant) => plant.art.path),
          ),
        );
        setPlants(
          response.data.featureLists.flatMap((element) =>
            element.plant.flatMap((plant) => plant.pic),
          ),
        );
        setArts(
          response.data.featureLists.flatMap((element) =>
            element.plant.flatMap((plant) => plant.art),
          ),
        );
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []); //fecth data from the server

  useEffect(() => {
    if (featuredPicsArray.length > 0) {
      setCurrentPic(featuredPicsArray[0]);
    }
  }, [featuredPicsArray]);

  const handlePrevious = () => {
    if (picsArrayIndex > 0) {
      setPicsArrayIndex(picsArrayIndex - 1);
    }
  };

  const handleNext = () => {
    if (picsArrayIndex < featuredPicsArray.length - 1) {
      setPicsArrayIndex(picsArrayIndex + 1);
    }
  };

  useEffect(() => {
    if (featuredPicsArray.length > 0) {
      setCurrentPic(featuredPicsArray[picsArrayIndex]);
    }
  }, [picsArrayIndex, featuredPicsArray]);

  // const logout = () => {
  //   localStorage.removeItem("token");
  //   window.location.reload();
  // };

  // log out function for possible usage
  return (
    <section className="home">
      <div id="page-container">
        <div className="part1">
          <Navbar />
          <nav className="featureBox">
            <div className="featuredTopBar">
              <h4 className="plantInBloom">Plant in Bloom</h4>
            </div>
            <div className="featuredColumn">
              <div className="featuredPics">
                <button className="prevBtn" onClick={handlePrevious}>
                  <PreviousIcon width={70} height={70} />
                </button>
                <div className="posts" id="posta">
                  {loading ? (
                    <div className="picLoad">
                      <div
                        style={{
                          height: "19vw",
                          width: "36vw",
                          backgroundColor: "rgba(185, 185, 185, 0.7)",
                        }}
                      />
                      <div className="picLabelLoad" />
                    </div>
                  ) : (
                    currentPic && (
                      <div className="picContainer">
                        <img
                          src={currentPic}
                          alt="PlantPic"
                          className="currentPics"
                        />

                        <div className="currentPicLabel">
                          <p>
                            Photographer:{" "}
                            {plants[picsArrayIndex].takenBy ?? "Unknown"}
                          </p>
                          <p>
                            Time: {plants[picsArrayIndex].time ?? "Unknown"}
                          </p>
                          <p>
                            Setting:{" "}
                            {plants[picsArrayIndex].setting ?? "Unknown"}
                          </p>
                        </div>
                      </div>
                    )
                  )}
                </div>
                <div className="posts" id="posta">
                  {loading ? (
                    <div className="artLoad">
                      <div
                        style={{
                          height: "25.5vw",
                          width: "18.5vw",
                          backgroundColor: "rgba(185, 185, 185, 0.7)",
                        }}
                      />
                      <div className="artLabelLoad" />
                    </div>
                  ) : (
                    artPaths[picsArrayIndex] && (
                      <div className="artContainer">
                        <img
                          src={artPaths[picsArrayIndex]}
                          alt="PlantArt"
                          className="currentArts"
                        />
                        <div className="artLabel">
                          <p style={{ margin: "0", marginLeft: "8px" }}>
                            By {arts[picsArrayIndex].artist ?? "Unknown"}
                          </p>
                          <p style={{ margin: "0", marginLeft: "8px" }}>
                            {arts[picsArrayIndex].postingtime ?? "Unknown"}
                          </p>
                          <p style={{ margin: "0", marginLeft: "8px" }}>
                            Location:{" "}
                            {arts[picsArrayIndex].location ?? "Unknown"}
                          </p>
                        </div>
                      </div>
                    )
                  )}
                </div>
                <button className="nextBtn" onClick={handleNext}>
                  <NextIcon width={70} height={70} />
                </button>
              </div>
              <div className="description">
                <div className="descriptionLeft">
                  <h2
                    style={{
                      display: "flex",
                      position: "relative",
                      left: "5.5em",
                      fontWeight: "500",
                      margin: "0",
                    }}
                    className="descriptionH2"
                  >
                    Species:{" "}
                    <p
                      style={{
                        fontStyle: "italic",
                        display: "inline-flex",
                        margin: "0",
                        position: "relative",
                        marginLeft: "6px",
                      }}
                    >
                      {currentPic && plants[picsArrayIndex].plant}
                    </p>
                  </h2>
                  <button
                    type="button"
                    onClick={() => handleGet(plants[picsArrayIndex].plant)}
                    className="forDetail"
                  >
                    Detailed Information
                  </button>
                </div>
                <h2
                  style={{
                    display: "flex",
                    position: "absolute",
                    right: "5.5em",
                    fontWeight: "500",
                    margin: "2rem",
                    bottom: "1.1rem",
                  }}
                  className="descriptionH2"
                >
                  Flowering Season:{" "}
                  <p
                    style={{
                      display: "inline-flex",
                      margin: "0",
                      position: "relative",
                      marginLeft: "6px",
                    }}
                  >
                    {currentPic && plants[picsArrayIndex].season}
                  </p>
                </h2>
              </div>
            </div>
          </nav>
        </div>
        <div className="hline2" />
        <div className="part2">
          <div id="bottomHalf">
            <h1 className="searchtitle">
              校内植物检索 Search for Plant species on Campus
            </h1>
            <SearchBar
              handleGet={handleGet}
              searchResults={searchResults}
              query={query}
              handleSearch={handleSearch}
            />
          </div>
          <br />
        </div>
        <div className="activity">
          <div className="activityBox">
            <div className="activityTop">
              <div>Recent Activities 近期活动</div>
              <div>Botany Guided Tour Sign-up 植物导赏报名</div>
            </div>
            <div className="activityBtm">
              <div>
                <img src="./activityPoster.jpg" alt="test" />
              </div>
              <div>
                <iframe
                  width="490px"
                  height="350px"
                  title="form"
                  src="https://forms.office.com/Pages/ResponsePage.aspx?id=4uHGy7umAkC73G2okqBRp7ZfkPP9KsxHgGlnUXrSuIlURVQ3U01SWTJZWldQSFlWUUNJUzIyNExVNC4u&embed=true"
                />
              </div>
            </div>
          </div>
        </div>

        <footer className="beianContainer">
          <a
            href="https://beian.miit.gov.cn"
            target="_blank"
            rel="noreferrer"
            className="beian"
          >
            湘ICP备2023027594号-1
          </a>
        </footer>
      </div>
    </section>
  );
};

export default Home;
