// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../public/homescreen.jpg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../public/doc3.jpg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../public/doc2.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `section.creationPart1 {
  background:
    url(${___CSS_LOADER_URL_REPLACEMENT_0___}) top center / 100vw 60rem no-repeat,
    url(${___CSS_LOADER_URL_REPLACEMENT_1___}) center center / 100vw auto no-repeat,
    url(${___CSS_LOADER_URL_REPLACEMENT_2___}) bottom center / 100vw auto no-repeat;
  border: none;
  position: relative;
  margin: 0;
}
div.subPageLinks {
  display: flex;
  flex-direction: row;
  width: 92vw;
  height: 65px;
  position: relative;
  z-index: 1000;
  background-color: #f4fae8;
  margin: 0 auto;
}
button.subPageButton {
  color: #2d4228;
  width: 15vw;
  font-weight: 600;
}
button.active {
  color: #f4fae8;
  background-color: #516d4e;
}
section.loadingCreation {
  width: 100%;
  height: 100%;
  z-index: 10000;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #63895f;
}
`, "",{"version":3,"sources":["webpack://./src/creation.css"],"names":[],"mappings":"AAAA;EACE;;;gFAGgE;EAChE,YAAY;EACZ,kBAAkB;EAClB,SAAS;AACX;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,aAAa;EACb,yBAAyB;EACzB,cAAc;AAChB;AACA;EACE,cAAc;EACd,WAAW;EACX,gBAAgB;AAClB;AACA;EACE,cAAc;EACd,yBAAyB;AAC3B;AACA;EACE,WAAW;EACX,YAAY;EACZ,cAAc;EACd,eAAe;EACf,MAAM;EACN,OAAO;EACP,yBAAyB;AAC3B","sourcesContent":["section.creationPart1 {\n  background:\n    url(\"../public/homescreen.jpg\") top center / 100vw 60rem no-repeat,\n    url(\"../public/doc3.jpg\") center center / 100vw auto no-repeat,\n    url(\"../public/doc2.jpg\") bottom center / 100vw auto no-repeat;\n  border: none;\n  position: relative;\n  margin: 0;\n}\ndiv.subPageLinks {\n  display: flex;\n  flex-direction: row;\n  width: 92vw;\n  height: 65px;\n  position: relative;\n  z-index: 1000;\n  background-color: #f4fae8;\n  margin: 0 auto;\n}\nbutton.subPageButton {\n  color: #2d4228;\n  width: 15vw;\n  font-weight: 600;\n}\nbutton.active {\n  color: #f4fae8;\n  background-color: #516d4e;\n}\nsection.loadingCreation {\n  width: 100%;\n  height: 100%;\n  z-index: 10000;\n  position: fixed;\n  top: 0;\n  left: 0;\n  background-color: #63895f;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
