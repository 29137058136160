// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.letter {
  position: relative;
  display: flex;
  flex-direction: column;
}
div.letterTop {
  display: flex;
}
button.lt {
  font-weight: 600;
  position: relative;
}
button.lt:hover {
  text-decoration: underline;
}
button.add {
  text-decoration: underline;
}
button.col:focus {
  text-decoration: underline;
}
button.col {
  position: relative;
}
button.cold {
  display: none;
}
button.let {
  position: relative;
  right: 11vw;
}
div.postG {
  position: relative;
  right: 12vw;
  margin-bottom: 0.5vh;
}
button.glossaryBtns {
  font-weight: 200;
  font-size: 1.3rem;
}
button.glossaryBtns:hover {
  text-decoration: underline;
}
div.posts {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
`, "",{"version":3,"sources":["webpack://./src/letterbutton.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,sBAAsB;AACxB;AACA;EACE,aAAa;AACf;AACA;EACE,gBAAgB;EAChB,kBAAkB;AACpB;AACA;EACE,0BAA0B;AAC5B;AACA;EACE,0BAA0B;AAC5B;AACA;EACE,0BAA0B;AAC5B;AACA;EACE,kBAAkB;AACpB;AACA;EACE,aAAa;AACf;AACA;EACE,kBAAkB;EAClB,WAAW;AACb;AACA;EACE,kBAAkB;EAClB,WAAW;EACX,oBAAoB;AACtB;AACA;EACE,gBAAgB;EAChB,iBAAiB;AACnB;AACA;EACE,0BAA0B;AAC5B;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;AACzB","sourcesContent":["div.letter {\n  position: relative;\n  display: flex;\n  flex-direction: column;\n}\ndiv.letterTop {\n  display: flex;\n}\nbutton.lt {\n  font-weight: 600;\n  position: relative;\n}\nbutton.lt:hover {\n  text-decoration: underline;\n}\nbutton.add {\n  text-decoration: underline;\n}\nbutton.col:focus {\n  text-decoration: underline;\n}\nbutton.col {\n  position: relative;\n}\nbutton.cold {\n  display: none;\n}\nbutton.let {\n  position: relative;\n  right: 11vw;\n}\ndiv.postG {\n  position: relative;\n  right: 12vw;\n  margin-bottom: 0.5vh;\n}\nbutton.glossaryBtns {\n  font-weight: 200;\n  font-size: 1.3rem;\n}\nbutton.glossaryBtns:hover {\n  text-decoration: underline;\n}\ndiv.posts {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
