// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../public/homescreen.jpg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../public/part2.jpg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../public/doc2.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `section.editPage {
  background:
    url(${___CSS_LOADER_URL_REPLACEMENT_0___}) top center / 100vw 60rem no-repeat,
    url(${___CSS_LOADER_URL_REPLACEMENT_1___}) center center / 100vw auto no-repeat,
    url(${___CSS_LOADER_URL_REPLACEMENT_2___}) bottom center / 100vw auto no-repeat;
}
button.toAdminAuth {
  color: white;
  background-color: #739666;
  font-size: 1.4rem;
  position: relative;
  margin-top: 1rem;
  margin-left: 1rem;
  padding: 0.8rem 1.7rem 0.8rem 1.7rem;
}
/* #516d4e */
/* #f4fae8 */
div.previewEdit {
  background-color: #f4fae8;
  width: 92vw;
  margin: 0 auto;
}
div.textTop {
  background-color: #516d4e;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}
div.textInfoEditBox {
  position: relative;
}
div.topBtm {
  margin-left: 4vw;
}
button.editTextBtn {
  color: white;
  background-color: #739666;
  font-size: 1.4rem;
  position: relative;
  padding: 0.5rem 1.3rem 0.5rem 1.3rem;
}
h1.photoTitle {
  background-color: #516d4e;
  color: white;
  padding: 1rem 0 1rem 0;
}
h1.artEditTitle {
  background-color: #516d4e;
  color: white;
  padding: 1rem 0 1rem 0;
}
div.textBtm {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
`, "",{"version":3,"sources":["webpack://./src/editPage.css"],"names":[],"mappings":"AAAA;EACE;;;gFAGgE;AAClE;AACA;EACE,YAAY;EACZ,yBAAyB;EACzB,iBAAiB;EACjB,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,oCAAoC;AACtC;AACA,YAAY;AACZ,YAAY;AACZ;EACE,yBAAyB;EACzB,WAAW;EACX,cAAc;AAChB;AACA;EACE,yBAAyB;EACzB,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,YAAY;EACZ,yBAAyB;EACzB,iBAAiB;EACjB,kBAAkB;EAClB,oCAAoC;AACtC;AACA;EACE,yBAAyB;EACzB,YAAY;EACZ,sBAAsB;AACxB;AACA;EACE,yBAAyB;EACzB,YAAY;EACZ,sBAAsB;AACxB;AACA;EACE,aAAa;EACb,qCAAqC;AACvC","sourcesContent":["section.editPage {\n  background:\n    url(\"../public/homescreen.jpg\") top center / 100vw 60rem no-repeat,\n    url(\"../public/part2.jpg\") center center / 100vw auto no-repeat,\n    url(\"../public/doc2.jpg\") bottom center / 100vw auto no-repeat;\n}\nbutton.toAdminAuth {\n  color: white;\n  background-color: #739666;\n  font-size: 1.4rem;\n  position: relative;\n  margin-top: 1rem;\n  margin-left: 1rem;\n  padding: 0.8rem 1.7rem 0.8rem 1.7rem;\n}\n/* #516d4e */\n/* #f4fae8 */\ndiv.previewEdit {\n  background-color: #f4fae8;\n  width: 92vw;\n  margin: 0 auto;\n}\ndiv.textTop {\n  background-color: #516d4e;\n  color: white;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\ndiv.textInfoEditBox {\n  position: relative;\n}\ndiv.topBtm {\n  margin-left: 4vw;\n}\nbutton.editTextBtn {\n  color: white;\n  background-color: #739666;\n  font-size: 1.4rem;\n  position: relative;\n  padding: 0.5rem 1.3rem 0.5rem 1.3rem;\n}\nh1.photoTitle {\n  background-color: #516d4e;\n  color: white;\n  padding: 1rem 0 1rem 0;\n}\nh1.artEditTitle {\n  background-color: #516d4e;\n  color: white;\n  padding: 1rem 0 1rem 0;\n}\ndiv.textBtm {\n  display: grid;\n  grid-template-columns: repeat(2, 1fr);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
